//import './plugins/fontawesome'
import '@babel/polyfill'
import 'mutationobserver-shim'
//import './plugins/bootstrap-vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

createApp(App).use(i18n).use(store).use(router).mount('#app')
