<template>
  <div class="quizz-list">
    <h2 class="mainTitle">{{ $t('default.quantifier') }}</h2>
    <div class="row activity-ctn">
      <div class="col-md-8 content">
        <div class="wrapper">
          <div class="title">
            <h2>{{ $t('default.quizz-title') }}</h2>
          </div>
          <div class="controls">
             <router-link :to="{name: 'infos', query: {step:1}}">{{ $t('default.commencer') }}</router-link>
             <small>{{  $t('default.estimated-time') }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-4 img">
        <img src="world.svg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    //HelloWorld,
  },
    mounted() {
      this.$store.commit('displayNav', false);
      this.$store.commit('displayHeader', false);
    },
}
</script>

<style lang="less">
  .mainTitle {
    margin: 45px 0;
    text-align: left;
  }
  .activity-ctn {
    background-color: white;
    padding: 60px;
    height: 360px;
  }
  .quizz-list {
    flex-direction: column;
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      flex-direction: column;
    }
  }
  .content {
    height: 100%;
    display: flex;
    align-items: center;
    .title {
      margin-bottom: 20px;
    }
    a {
      background-color: #20194C;
      color: white;
      border-radius: 2px;
      border: none;
      padding: 8px 20px;
      font-size: 18px;
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 762px) {
      .quizz-list {
        flex-direction: column;
        .wrapper {
          .controls {
            display: flex;
            flex-direction: column-reverse;
          }
          small {
            margin-bottom: 20px;
            margin-top: -25px;
          }
          * {
            text-align: left;
          }
        }
      }
      .activity-ctn {
        margin: 0px;
        padding: 20px;
        height: 180px;
        display: flex;
        flex-direction: row;
        .content {
          padding: 0;
        }
        .img {
          height: 100%;
          display: flex;
          img {
            max-width: 200px;
            width: 30vw;
          }
        }
      }
  }
</style>