{
	"activites": "Activités",
	"alcool": "Boissons alcoolisées",
	"auberge": "Auberge de jeunessee",
	"avion": "Avion",
	"baignade": "Baignade",
	"bilan": "Résultat",
	"bilan-titre-activites": "Activités <span>{n1} tonne(s)</span>",
	"bilan-titre-logement": "Logement <span>{n1} tonne(s)</span>",
	"bilan-titre-repas": "Repas & Boissons <span>{n1} tonne(s)</span>",
	"bilan-titre-transport": "Transports <span>{n1} tonne(s)</span>",
	"bnb": "Bed & Breakfast",
	"bus": "Bus",
	"cafe": "Café",
	"calcul-arbres": "Du CO2 absorbé par {n1} arbres en 1 année",
	"calcul-nouilles": "L’énergie nécessaire pour cuire {n1} kg de nouilles",
	"calcul-smartphone": "L’énergie produite pour recharger {n1} smartphones",
	"camping": "Camping",
	"commencer": "Commencer",
	"compensez-vos-emissions": "Compensez vos émissions",
	"details": "Détails",
	"duree": "Durée du séjour",
	"eau-bouteille": "Eau en bouteille",
	"eau-robinet": "Eau du robinet",
	"empreinte-par-personne": "Empreinte par personne",
	"estimated-time": "Temps estimé: 5 minutes",
	"fois": "fois",
	"hotel1-2": "Hôtel 1-2 étoiles",
	"hotel3-4": "Hôtel 3-4 étoiles",
	"informations-generales": "Informations générales",
	"jours": "jour(s)",
	"jus-fruit": "Jus de fruit",
	"km": "km",
	"litres-jour": "lt/jour",
	"tasses-jour": "/jour",
	"logement": "Logement",
	"musee": "Musée",
	"nombre": "Nombre de personnes",
	"nuits": "nuits",
	"obligatoire": "Obligatoire",
	"personne": "personne",
	"personnes": "personnes",
	"petit-dejeuner": "Petit-déjeuner",
	"pique-nique": "Pique-nique",
	"plus-de-details": "Plus de détails",
	"quantifier": "Quantifier vos émissions CO2 et investisser dans des projet locaux.",
	"quizz-title": "Voyages & découvertes",
	"randonnee": "Randonnée",
	"reduire": "Réduire",
	"repas-boissons": "Repas & Boissons",
	"restaurant": "Restaurant",
	"ski": "Ski",
	"soda": "Soda",
	"sous-moyenne": "{n1} sous la moyenne",
	"soutenez-des-projets": "Pour compenser vos émissions, soutenez volontairement des projets de réduction du CO2 avec Carbon Fri.",
	"sur-moyenne": "{n1} au-dessus la moyenne",
	"telecabine": "Télécabine",
	"titre-activites": "Activité·s durant le séjour",
	"titre-bilan": "Votre voyage a produit en tout {n1} tonne(s) de CO2, avec une moyenne journalière de {n2} tonne(s).",
	"titre-equivalent": "{n1} tonne(s) de CO2, c'est l'équivalent de",
	"titre-logement": "Type de logement",
	"titre-projets-soutenus": "Projets soutenus par Carbon Fri",
	"titre-repas-sejour": "Nombres de repas durant le séjour par personne",
	"titre-transport-sur-place": "Moyen•s de transport utilisé•s sur place",
	"titre-transport-venir": "Moyen•s de transport utilisé•s pour venir à Fribourg",
	"tonne-co2": "{n1} tonne(s) de CO2",
	"train": "Train",
	"transports": "Transports",
	"velo": "Vélo",
	"visites": "visites",
	"voiture": "Voiture",
	"moyenne-suisse": "Voyage moyen<br />en suisse",
	"votre-sejour": "Votre séjour",
	"site-title": "CarbonFri - Calculateur CO2",
	"quit": "Quitter",

}
