// ./components/Header.vue
<script>
import store from '../store';
export default {
    name: 'Header',
    computed: {
        displayNav: () => store.state.displayNav,
        displayHeader: () => store.state.displayHeader,
        nbPeople: () => store.state.nbPeople,
        duration: () => store.state.duration,
        quitLink: function(){
            if(this.$i18n.locale=='de'){
                return 'https://www.carbonfri.ch/de-index';
            }else {
                return 'https://www.carbonfri.ch/';
            }
        }
    },
    data () {
        return { langs: ['fr', 'de'] }
    }
}
</script>

<template>
    <div class="row header">
        <div class="col-md-2 col-3 text-left"><a :href="quitLink"><span class="carret">◀</span>{{ $t('default.quit') }}</a></div>
        <div class="col-md-8 col-3 logo">
            <div class="img-wrapper">
                <img src="Logo.svg">
            </div></div>
        <div class="col-md-2 col-3 text-right">
            <div class="locale-changer">
                <select v-model="$i18n.locale">
                  <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                </select>
              </div>
        </div>
    </div>
    <hr>
    <div class="wrapper" v-if="displayNav || displayHeader">
        <div class="row infos">
            <div class="lefts">
                <h1>{{ $t('default.quizz-title') }}</h1>
            </div>
            <div class="rights">
                <div class="wrapper">
                    <div class="people info">
                        <img src="users.svg">
                        {{nbPeople}} {{ $t('default.personne') }}
                    </div>
                    <div class="days info">
                        <img src="calendar.svg">
                        {{duration}} {{ $t('default.jours') }}
                    </div>
                    <!--<div class="days info" v-if="displayHeader">
                        <router-link :to="{name: 'infos', query: {step:1}}">Editer</router-link>
                    </div>-->
                </div>
            </div>
        </div>
        <hr>
    </div>
</template>


<style lang="less">
    .carret {
        font-size: 6pt;
        color: #5C7FBE;
        margin-right: 3px;
    }
    .text-left {
        a {
            color: rgba(32, 25, 76, 1);
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 10px;
            border-radius: 4px;
            border: rgba(92, 127, 190, 0.2) solid 1px;
            padding: 5px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .text-right {
        justify-content: flex-end;
    }
    a {
        text-decoration: none;
    }

    select {
        background-color: #f6f7f8;
        text-transform: uppercase;
        width: 50px;
        border: #281e54 solid 1px;
        border-radius: 3px;
    }
    .header {
        height: 70px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .logo {
            justify-content: center;
        }
        div {
            display: flex;
            align-items: center;
            align-content: center;
            height: 100%;
        }
    }
    .lefts, .rights {
        width: 100%;
    }
    .lefts {
        text-align: left;
    }
    .rights {
        text-align: right;
    }
    .infos {
        padding: 0 15px;
        display: flex;
        justify-content: space-between; 
        .wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            > div {
                margin-left: 20px; 
            }
        }
    }
    h1 {
        font-size: 28px;
        text-align: left;
        font-weight: 300;
    }
    @media screen and (max-width: 762px) {
        .text-left {
            a {
                padding: 10px;
                font-size: 12px;
                margin-right: 20px;
            }
        }
        .carret {
            display: none;
        }
        h2 {
            font-size: 20px;
            font-family: 'Rubik';
        }
        .img-wrapper img {
            width: 90%;
        }
        hr {
            margin-bottom: 0;
        }
        .infos {
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
            padding: 0;
            .wrapper {
                justify-content: flex-start;
                .people {
                    margin-left: 0;
                }
            }
        }
    }
</style>