import { createStore } from 'vuex'
import {mapState } from 'vuex'

export default createStore({
  state: {
    nbPeople: 1,
    duration: 1,
    // Transports pour venir
    train1: 0,
    bus1: 0,
    car1: 0,
    bike1: 0,
    plane1: 0,
    // Transports sur place
    train2: 0,
    bus2: 0,
    car2: 0,
    bike2: 0,
    cablecar: 0,
    // repas
    restaurant: 0,
    piquenique: 0,
    dejeuner: 0,
    // Boissons
    robinet: 0,
    bouteille: 0,
    cafe: 0,
    soda: 0,
    jusfruit: 0,
    alcool: 0,
    // Activités
    ski: 0,
    rando: 0,
    baignade: 0,
    musee: 0,
    //logement
    hotel1: 0,
    hotel4:0,
    auberge:0,
    bnb: 0,
    camping: 0,
    // Global
    advancement: {
      infos: 0,
      transports: 0,
      repas: 0,
      activites: 0,
      logement: 0
    },
    // Behavior attributes
    displayNav: false,
    displayHeader: false,
  },
  mutations: {
    nbPeople (state, value) {
      state.nbPeople = value;
    },
    duration (state, value) {
      state.duration = value;
    },
    // Transports
    train1(state, value) {
      state.train1 = value
    },
    train2(state, value) {
      state.train2 = value
    },
    bus1(state, value) {
      state.bus1 = value
    },
    bus2(state, value) {
      state.bus2 = value
    },
    car1(state, value) {
      state.car1 = value
    },
    car2(state, value) {
      state.car2 = value
    },
    bike1(state, value) {
      state.bike1 = value
    },
    bike2(state, value) {
      state.bike2 = value
    },
    plane1(state, value) {
      state.plane1 = value
    },
    cablecar(state, value) {
      state.cablecar = value
    },
    // Repas
    restaurant(state, value) {
      state.restaurant = value
    },
    dejeuner(state, value) {
      state.dejeuner = value
    },
    piquenique(state, value) {
      state.piquenique = value
    },
    // Boissons
    robinet(state, value) {
      state.robinet = value
    },
    bouteille(state, value) {
      state.bouteille = value
    },
    cafe(state, value) {
      state.cafe = value
    },
    soda(state, value) {
      state.soda = value
    },
    jusfruit(state, value) {
      state.jusfruit = value
    },
    alcool(state, value) {
      state.alcool = value
    },
    // Activites
    ski(state, value) {
      state.ski = value
    },
    rando(state, value) {
      state.rando = value
    },
    baignade(state, value) {
      state.baignade = value
    },
    musee(state, value) {
      state.musee = value
    },
    // Logement
    hotel1(state, value) {
      state.hotel1 = value
    },
    hotel4(state, value) {
      state.hotel4 = value
    },
    camping(state, value) {
      state.camping = value
    },
    auberge(state, value) {
      state.auberge = value
    },
    bnb(state, value) {
      state.bnb = value
    },
    //GLOBAL

    displayNav(state, value) {
      state.displayNav = value
    },
    displayHeader(state, value) {
      state.displayHeader = value
    },
    advancement(state, value) {
      state.advancement = value
    }
  },
  actions: {
  },
  modules: {
  },
  computed: {
    ...mapState({
      nbPeople: state => state.nbPeople,
    })
  },
})
