// ./components/Navigation.vue
<script>
import Advancement from '@/components/Advancement.vue'
import store from '../store';
export default {
    name: 'Navigation',
    created() {
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        if(params.get("l")) {
            this.$i18n.locale = 'de';
        }
    },
    components: {
        Advancement
    },
    methods: {
        subIsActive: function (input) {
            const paths = Array.isArray(input) ? input : [input];

            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 // current path starts with this path string
            })
        }
    },
    computed: {
        advancement: () => store.state.advancement,
        displayNav: () => store.state.displayNav,
    }
}
</script>

<template>
    <nav id="nav" v-if="displayNav">
        <ul>
            <li><router-link :to="{name: 'infos', query: {step:1}}">1. {{ $t('default.informations-generales') }}</router-link><Advancement :percent="advancement.infos" :disabled="1" /></li>
            <li><router-link class="" :to="{name: 'transports', query: {step:1}}">2. {{ $t('default.transports') }}</router-link><Advancement :percent="advancement.transports"/></li>
            <li><router-link class="" :to="{name: 'repas', query: {step:1}}">3. {{ $t('default.repas-boissons') }}</router-link><Advancement :percent="advancement.repas"/></li>
            <li><router-link class="" :to="{name: 'activites', query: {step:1}}">4. {{ $t('default.activites') }}</router-link><Advancement :percent="advancement.activites"/></li>
            <li><router-link class="" :to="{name: 'logement', query: {step:1}}">5. {{ $t('default.logement') }}</router-link><Advancement :percent="advancement.logement"/></li>
            <li><router-link class="" :to="{name: 'bilan', query: {step:1}}">6. {{ $t('default.bilan') }}</router-link></li>
        </ul>
    </nav>
</template>

<style lang="less">
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    nav#nav {
        margin-bottom: 60px;
        padding: 30px 0;
        ul {
            width: 100%;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-start;
            list-style: none;
            li {
                margin-right: 40px;
                display: flex;
                align-items: center;
                a {
                    text-decoration: none;
                    font-weight: 300;
                    &.disabled {
                        font-weight: 400;
                    }
                }
                .router-link-active {
                    font-weight: 400;
                }
            }
        }
    }
    @media screen and (max-width: 762px) {
        nav#nav {
            margin-bottom: 0;
            ul {
                flex-direction: column;
            }
        }
    }
</style>