<template>
    <div class="advancement-bar" v-if="percent <= 100 && percent != 0">
        <div class="bar" :style="'width:'+ percent +'%'"></div>
    </div>
    <div class="advancement-filled" v-else-if="percent > 100">
        ✓
    </div>
</template>

<script>
    export default {
        name: 'Advancement',
        props: {
            percent: {
                type: Number,
                default: 0,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false
            }
        }
    }
</script>

<style lang="less">
    .advancement-bar {
        margin-left: 4px;
        width: 36px;
        height: 12px;
        background-color: rgba(92, 127, 190, 0.2);
        border-radius: 22px;
        padding: 2px;
        .advancement-bar {
            margin-left: 5px;
        }
        .bar {
            border-radius: 22px;
            transition: all linear .3s;
            height: 8px;
            background: linear-gradient(270deg, rgba(92,127,190,1) 0%, rgba(100,155,255,1) 100%);
        }
    }
    .advancement-filled {
        background: linear-gradient(270deg, rgba(92,127,190,1) 0%, rgba(100,155,255,1) 100%);
        width: 14px;
        height: 14px;
        margin-left: 5px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 8pt;
    }
</style>