<template>
  <Header />
  <router-view v-slot="{ Component }">
    <Navigation />
    <transition name="scale" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="less">
  body {
    background-color: #F6F7F8;
    font-family: 'Rubik', sans-serif !important;
    font-weight: 300;
  }
  html {
    scroll-behavior: smooth;
  }
  h1, h2, h3, h4 {
    font-weight: 300;
  }

  button {
    background-color: #20194C;
    color: white;
    border-radius: 2px;
    border: none;
    padding: 8px 20px;
    font-size: 16px;
    float: left;
    margin-left: 15px;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-family: 'Rubik', sans-serif !important;
    font-weight: 300;
    padding-bottom: 50px;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.5s ease;
  }


  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
</style>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'

export default {
  components: {
    Navigation,
    Header
  },
  watch:{
    $route (){
      var el = document.getElementsByClassName("hello");
      el = el[0];
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  } 
}
</script> 
