{
	"activites": "Aktivitäten",
	"alcool": "Alkoholische Getränke",
	"auberge": "Jugendherberge",
	"avion": "Flugzeug",
	"baignade": "Baden",
	"bilan": "Ergebnis",
	"bilan-titre-activites": "Aktivitäten <span>{n1} Tonne(n)</span>",
	"bilan-titre-logement": "Gehäuse <span>{n1} Tonne(n)</span>",
	"bilan-titre-repas": "Mahlzeiten & Getränke <span>{n1} Tonne(n)</span>",
	"bilan-titre-transport": "Transport <span>{n1} Tonne(n)</span>",
	"bnb": "Übernachtung & Frühstück",
	"bus": "Bus",
	"cafe": "Kaffee",
	"calcul-arbres": "CO2-Absorption durch {n1} Bäume in 1 Jahr",
	"calcul-nouilles": "Die zum Kochen von {n1} kg Nudeln erforderliche Energie",
	"calcul-smartphone": "Die zum Laden von {n1} -Smartphones erzeugte Energie",
	"camping": "Campingplatz",
	"commencer": "Start",
	"compensez-vos-emissions": "Kompensieren Sie Ihre Emissionen",
	"details": "Details",
	"duree": "Dauer des Aufenthalts",
	"eau-bouteille": "Wasser in Flaschen",
	"eau-robinet": "Leitungswasser",
	"empreinte-par-personne": "Fußabdruck pro Person",
	"estimated-time": "Geschätzte Zeit: 5 Minuten",
	"fois": "mal",
	"hotel1-2": "Hotel 1-2 Sterne",
	"hotel3-4": "3-4 Sterne Hotel",
	"informations-generales": "Allgemeine Informationen",
	"jours": "Tag(e)",
	"jus-fruit": "Fruchtsaft",
	"km": "km",
	"litres-jour": "Lt/Tag",
	"tasses-jour": "/Tag",
	"logement": "Gehäuse",
	"musee": "Museum",
	"nombre": "Anzahl der Personen",
	"nuits": "Nächte",
	"obligatoire": "Obligatorisch",
	"personne": "Person",
	"personnes": "Personen",
	"petit-dejeuner": "Frühstück",
	"pique-nique": "Picknick",
	"plus-de-details": "Mehr Details",
	"quantifier": "Quantifizieren Sie Ihre CO2-Emissionen und investieren Sie in lokale Projekte.",
	"quizz-title": "Reisen & Entdeckungen",
	"randonnee": "Wandern",
	"reduire": "Reduzieren Sie",
	"repas-boissons": "Mahlzeiten & Getränke",
	"restaurant": "Restaurant",
	"ski": "Ski",
	"soda": "Soda",
	"sous-moyenne": "{n1} unter dem Mittelwert",
	"soutenez-des-projets": "Um Ihre Emissionen auszugleichen, unterstützen Sie freiwillig CO2-Reduktionsprojekte mit Carbon Fri.",
	"sur-moyenne": "{n1} über dem Durchschnitt",
	"telecabine": "Gondelbahn",
	"titre-activites": "Aktivitäten während des Aufenthalts",
	"titre-bilan": "Ihre Reise verursachte insgesamt {n1} Tonne(n) CO2, mit einem Tagesdurchschnitt von {n2} Tonne(n).",
	"titre-equivalent": "{n1} Tonne(n) CO2, was dem Äquivalent von",
	"titre-logement": "Art der Unterkunft",
	"titre-projets-soutenus": "Unterstützte Projekte von Carbon Fri",
	"titre-repas-sejour": "Anzahl der Mahlzeiten während des Aufenthaltes pro Person",
	"titre-transport-sur-place": "Vor Ort verwendete Transportmittel",
	"titre-transport-venir": "Genutzte Verkehrsmittel zur Anreise nach Fribourg",
	"tonne-co2": "{n1} Tonne(n) CO2",
	"train": "Zug",
	"transports": "Transport",
	"velo": "Fahrrad",
	"visites": "Besucht",
	"voiture": "Auto",
	"moyenne-suisse": "Durchschnittliche Reise<br/>in der Schweiz",
	"votre-sejour": "Ihr Aufenthalt",
	"site-title": "CarbonFri - CO2-Rechner",
	"quit": "Ausfahrt",
}
